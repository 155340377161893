import React, { useContext, useEffect, useState } from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useParams } from "react-router-dom";
import { AppProvider, UserContext } from './contexts/UserContexts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from './pages/error';
import Layout from './layout/Layout';
import LayoutTwo from './layout/LayoutTwo';
import Forums from './pages/forums/Forums';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import './App.css';
import EditMyProfile from './pages/Profile/EditMyProfile';
import ForumsCategory from './pages/forums/ForumsCategory';
import ExperiencePage from './pages/Experience/ExperiencePage';
import MemberProfile from './pages/Profile/MemberProfile';
import Message from './pages/message/Message';
import DashboardCategory from './pages/dashboard/DashboardCategory';
import * as QB from "quickblox/quickblox";
import Config from "./pages/message/Constant";
import MyMessage from './pages/Profile/MyMessage';
import ResetPassword from './pages/Reset-Password/ResetPassword';
import ChangePassword from './pages/Profile/ChangePassword';
import ForgotPassword from './pages/Profile/ForgotPassword';
import axios from './api/axios';
import { reactLocalStorage } from 'reactjs-localstorage';


QB.init(Config.APPLICATION_ID, Config.AUTH_KEY, Config.AUTH_SECRET, Config.ACCOUNT_KEY, Config.CONFIG);
window.QB = QB;

function EnsureBasePath({children}) {
  useEffect(() => {
    const basePath = process.env.REACT_APP_ROUTE_DIR || "/hae/";
    const currentPath = window.location.pathname;

    if (!currentPath.startsWith(basePath)) {
      window.location.replace(basePath);
    }
  }, []);

  return children;
}

function App() {
  const { id } = useParams();

  return (
    <React.Fragment>
      <ToastContainer
        className="toast-position"
        position="top-center"
      />
      <AppProvider>
        <EnsureBasePath>
          <MountedApp />
        </EnsureBasePath>
      </AppProvider>
    </React.Fragment>

  );
}

function MountedApp() {
  const { setConnectionState, userData, setUserData, isLoggedIn, sessionToken, setSessionToken } = useContext(UserContext);
  const loginDetails = userData?.chat_credentials || "";
  const token = localStorage.getItem(process.env.REACT_APP_NAME + '_Token');

  useEffect(() => {
    let payload = {
      api_token: token,
      vendor_id: userData?.vendor_id,
    };
    if (token){
      axios.get('/user/profile', { params: payload })
      .then((response) => {
        setUserData(response.data);
      }).catch(err => {
        console.error("User initialization error", err);
      });
    } 
  }, []);

  useEffect(() => {
    
    if (token && isLoggedIn) {
      if (sessionToken) {
        QB.startSessionWithToken(sessionToken, function (err, mySession) {
          if (err) {
            console.log('****Error in start session with token', err);
            createSession();

          } else {
            console.log('*****session data: ', mySession);
            if (mySession.session.user_id == 0) {
              createSession();
            } else {
              chatServerJoin(mySession.session);
            }

          }
        });
      } else {
        createSession();
      }
    }
  }, [token, isLoggedIn, sessionToken]);

  useEffect(() => {
    reactLocalStorage.setObject(process.env.REACT_APP_NAME + "_userData", userData);
  }, [userData]);

  useEffect(() => {
    QB.chat.onSessionExpiredListener = function (error) {
      if (error) {
        console.log('****onSessionExpiredListener - error: ', error);
      } else {
        console.log('****The session has expired.');
        setConnectionState(false);
        createSession();
      }
    }
  }, []);
  const createSession = () => {
    var params = { login: userData?.chat_credentials?.name, password: userData?.chat_credentials?.password };
    QB.createSession(params, function (error, result) {
      // callback function
      if (error) {
        toast.error(error.detail.base[0], {
          toastId: 'error1',
        });
      } else {
        localStorage.setItem(process.env.REACT_APP_NAME + '_userSession', JSON.stringify(result));
        localStorage.setItem(process.env.REACT_APP_NAME + "_userSessionToken", result.token);
        setSessionToken(result.token);
        //  chatServerJoin(result);
      }
    });
    // QB.createSession(function (error, result) {
    //   if (error) {
    //     console.log("*******error", error);
    //   } else {
    //     localStorage.setItem(process.env.REACT_APP_NAME + "_appSessionToken", result.token);
    //     localStorage.setItem(process.env.REACT_APP_NAME + '_appSession', JSON.stringify(result));
    //     loginUser(result);
    //   }
    // });

  };
  const loginUser = (data) => {
    var params = {
      token: data.token,
      login: loginDetails.name,
      password: loginDetails.password,
    };
    QB.login(params, function (error, result) {
      if (error) {
        console.log("****join user error: ", error);
      } else {
        console.log("*******user login", result);
      }
    });

  };
  const chatServerJoin = (data) => {
    let userId = data.user_id;
    let password = data.token;
    let params = { userId, password };

    QB.chat.connect(params, function (error, roster) {
      if (error) {
        console.log('*****SERVER ERROR RESPONSE ', error);
        createSession();
        setConnectionState(false);
      } else {
        console.log('*****SERVER SUCCESS RESPONSE ', roster);
        setConnectionState(true);
      }
    });

  };


  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: !token || !userData ? <Navigate to={"/login"} /> : <Layout />,
      children: [
        {
          path: "/",
          element: <Dashboard />
        }, {
          path: "/change-password",
          element: <ChangePassword />
        },
        {
          path: `discussion-board/:id`,
          element: <DashboardCategory />
        },
        {
          path: "my-account",
          element: <EditMyProfile />
        },
        {
          path: "forums/dashboard",
          element: <Forums />
        },
        {
          path: `forums/category/:id`,
          element: <ForumsCategory />
        },
        {
          path: `member-profile/:id`,
          element: <MemberProfile />
        }, {
          path: "message/:id",
          element: <Message />
        },
        {
          path: "my-message",
          element: <MyMessage />
        },

      ]
    },
    {
      path: "/",
      element: <LayoutTwo />,
      children: [
        {
          path: "forums/experience/:id",
          element: <ExperiencePage />
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    }
  ], { basename: process.env.REACT_APP_ROUTE_DIR });


  return <RouterProvider router={router} />
}


export default App;
